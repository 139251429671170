import React from 'react';
import { observer } from 'mobx-react';
import PurchasingInstructionsModel from './model';
import { Button, Col, Row, Card, Space, Form, Modal, Pagination, Select, Input, InputNumber, Cascader, Dropdown, Empty } from 'antd';
import styles from './index.less';
import { DashOutlined } from '@ant-design/icons';

@observer
export default class PriceTemplate extends React.Component {
  constructor(prop) {
    super(prop);
    this.store = new PurchasingInstructionsModel();
  }

  public declare store: PurchasingInstructionsModel;

  render() {
    const { formRef, getPriceCategoryName, onEdit, onDelete, unitOptions, cityList, pageData, total, current, pageSize, onShowSizeChange, getSecondary, firsList, secondList, onSubmit, setModalOpen, modalOpen } = this.store;
    return (
      <div className={styles.page}>
        <div>
          <Button onClick={setModalOpen}>新增模版</Button>
        </div>
        {!pageData.length ? (
          <div className={styles.empty}>
            <Empty />
          </div>
        ) : undefined}
        {pageData.length ? (
          <Space
            align="start"
            className={styles.content}
          >
            {pageData?.map((_, i) => (
              <div
                key={_.id}
                className={styles.card}
              >
                <div>
                  <span>
                    {_.regionName}
                    {' > '}
                    {_.parentCategoryName}
                    {' > '}
                    {_.categoryName}
                  </span>
                  <div>
                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: '1',
                            label: (
                              <a
                                onClick={(e) => {
                                  onEdit(_.id);
                                }}
                              >
                                编辑
                              </a>
                            ),
                          },
                          {
                            key: '2',
                            label: (
                              <a
                                onClick={() => {
                                  onDelete(_.id);
                                }}
                              >
                                删除
                              </a>
                            ),
                          },
                        ],
                      }}
                    >
                      <DashOutlined className={styles.editOutlined} />
                    </Dropdown>
                  </div>
                </div>
                <h1 className={styles.title}>{_.title}</h1>
                <div className={styles.detailList}>
                  {_?.detailList?.map((item) => (
                    <div
                      className={styles.detailListItem}
                      key={item.id}
                    >
                      <span className={styles.duration}>{item.duration}</span>
                      {getPriceCategoryName(item.priceCategory)}
                      <span className={styles.price}>{item.price}元</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Space>
        ) : undefined}
        <Pagination
          align="end"
          pageSizeOptions={[50, 100, 150, 200]}
          showSizeChanger
          onShowSizeChange={onShowSizeChange}
          current={current}
          pageSize={pageSize}
          total={total}
        />
        <Form
          scrollToFirstError
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 15 }}
          ref={formRef}
        >
          <Modal
            open={modalOpen}
            onCancel={setModalOpen}
            onOk={onSubmit}
            width={800}
          >
            <div className={styles.modalContent}>
              <Row className={styles.modalHeader}>
                <Col span={8}>
                  <Form.Item
                    label="区域"
                    name="regionId"
                    rules={[{ required: true }]}
                  >
                    <Cascader
                      fieldNames={{
                        label: 'name',
                        value: 'id',
                        children: 'childrenList',
                      }}
                      options={cityList}
                      className={styles.select}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="一级类别"
                    name="parentCategoryId"
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={(value) => {
                        getSecondary(value);
                      }}
                      options={firsList}
                      className={styles.select}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="二级类别"
                    name="categoryId"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={secondList}
                      className={styles.select}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="标题"
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 21 }}
                name="title"
                rules={[{ required: true }]}
              >
                <Input
                  maxLength={10}
                  autoComplete="off"
                />
              </Form.Item>
              {new Array(3).fill(0).map((_, i) => (
                <Form.Item
                  key={`${i.toString()}Form`}
                  noStyle
                  shouldUpdate
                >
                  {({ getFieldValue, setFieldValue }) => (
                    <Space className={styles.row}>
                      <Form.Item
                        noStyle
                        name={['detailList', `${i}`, 'id']}
                      />
                      标题
                      <Form.Item
                        noStyle
                        name={['detailList', `${i}`, 'title']}
                        rules={[{ required: true }]}
                      >
                        <Input
                          style={{ width: 100 }}
                          maxLength={8}
                          autoComplete="off"
                        />
                      </Form.Item>
                      会员时长
                      <Form.Item
                        noStyle
                        name={['detailList', `${i}`, 'duration']}
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          min={0}
                          precision={0}
                          max={100}
                        />
                      </Form.Item>
                      <Form.Item
                        noStyle
                        name={['detailList', `${i}`, 'priceCategory']}
                        rules={[{ required: true }]}
                      >
                        <Select
                          fieldNames={{
                            label: 'dictName',
                            value: 'dictValue',
                          }}
                          options={unitOptions}
                          className={styles.unit}
                        />
                      </Form.Item>{' '}
                      <span>价格</span>
                      <Form.Item
                        noStyle
                        name={['detailList', `${i}`, 'price']}
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          min={0}
                          precision={2}
                          max={9999}
                        />
                      </Form.Item>
                      元
                    </Space>
                  )}
                </Form.Item>
              ))}
            </div>
          </Modal>
        </Form>
      </div>
    );
  }
}
